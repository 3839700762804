import React from 'react';
import {
  IoBusinessOutline,
  IoDocumentsOutline,
  IoFileTrayOutline,
  IoLocationOutline,
  IoPersonOutline,
} from 'react-icons/io5';
import capitalize from 'lodash.capitalize';
import i18n from 'lib/i18n';
import { tType } from 'types';
import EmptyState from '.';

const { withTranslation } = i18n;

const ReadingsEmptyState = withTranslation()(({ t }) => (
  <EmptyState
    icon={IoFileTrayOutline}
    description={capitalize(
      t('empty_state_message', { value: t('empty_state_values.readings') }),
    )}
  />
));

ReadingsEmptyState.propTypes = {
  t: tType,
};

const MonitorsEmptyState = withTranslation()(({ t }) => (
  <EmptyState
    icon={IoFileTrayOutline}
    description={capitalize(
      t('empty_state_message', { value: t('empty_state_values.monitors') }),
    )}
  />
));

MonitorsEmptyState.propTypes = {
  t: tType,
};

const CompaniesEmptyState = withTranslation()(({ t }) => (
  <EmptyState
    icon={IoBusinessOutline}
    description={capitalize(
      t('empty_state_message', { value: t('empty_state_values.companies') }),
    )}
  />
));

CompaniesEmptyState.propTypes = {
  t: tType,
};

const InvoicesEmptyState = withTranslation()(({ t }) => (
  <EmptyState
    icon={IoDocumentsOutline}
    description={capitalize(
      t('empty_state_message', { value: t('empty_state_values.invoices') }),
    )}
  />
));

InvoicesEmptyState.propTypes = {
  t: tType,
};

const SnapshotItemsEmptyState = withTranslation()(({ t }) => (
  <EmptyState
    icon={IoDocumentsOutline}
    description={capitalize(
      t('empty_state_message', {
        value: t('empty_state_values.snapshot_items'),
      }),
    )}
  />
));

SnapshotItemsEmptyState.propTypes = {
  t: tType,
};

const UsersEmptyState = withTranslation()(({ t }) => (
  <EmptyState
    icon={IoPersonOutline}
    description={capitalize(
      t('empty_state_message', { value: t('empty_state_values.users') }),
    )}
  />
));

UsersEmptyState.propTypes = {
  t: tType,
};

const ZonesEmptyState = withTranslation()(({ t }) => (
  <EmptyState
    icon={IoLocationOutline}
    description={capitalize(
      t('empty_state_message', { value: t('empty_state_values.zones') }),
    )}
  />
));

ZonesEmptyState.propTypes = {
  t: tType,
};

const ConsumersEmptyState = withTranslation()(({ t }) => (
  <EmptyState
    icon={IoLocationOutline}
    description={capitalize(
      t('empty_state_message', { value: t('empty_state_values.consumers') }),
    )}
  />
));

ConsumersEmptyState.propTypes = {
  t: tType,
};

const FillEventsEmptyState = withTranslation()(({ t }) => (
  <EmptyState
    icon={IoFileTrayOutline}
    description={capitalize(
      t('empty_state_message', { value: t('empty_state_values.fill_events') }),
    )}
  />
));

FillEventsEmptyState.propTypes = {
  t: tType,
};

const LevelHistoryEmptyState = withTranslation()(({ t }) => (
  <EmptyState
    icon={IoFileTrayOutline}
    description={capitalize(
      t('empty_state_message', { value: t('empty_state_values.tank_levels') }),
    )}
  />
));

LevelHistoryEmptyState.propTypes = {
  t: tType,
};

export {
  MonitorsEmptyState,
  ReadingsEmptyState,
  CompaniesEmptyState,
  InvoicesEmptyState,
  UsersEmptyState,
  ZonesEmptyState,
  ConsumersEmptyState,
  FillEventsEmptyState,
  LevelHistoryEmptyState,
  SnapshotItemsEmptyState,
};
